<template>
  <div>
    <div class="automate-all-section" v-if="!user_data.parent_id && user_data.role == 'pro'">
        <b-card-text class="mb-0">
          Automate All
        </b-card-text>
        <b-form-checkbox
          v-model="automateAll"
          class="custom-control-danger"
          name="check-button"
          @change="triggerAutomateAll"
          switch
        />
    </div>
    <b-card-code
      no-body
      title="Upcoming Classes"
      v-if="mtIntStatus && zoomIntStatus"
    >
      <b-table
        :items="items"
        :fields="fields"
        striped
        responsive
      >
        <template #cell(startdatetime)="data">
          <div v-if="data.item.start_date">
            <p><strong>{{ data.item.start_time.substring(0, 5) | timeformat }}</strong></p>
            <p>{{ data.item.class_type.duration }} mins</p>
          </div>
        </template>

        <template #cell(location)="data">
          <div v-if="data.item.location.name" style="width: 150px">
            {{ data.item.location.name }}
          </div>
        </template>

        <template #cell(date)="data">
            {{ data.item.start_datetime | dateformat }}
        </template>

        <template #cell(zoomlink)="data">
          <div v-if="zoom_links[data.item.id]">
            <div v-if="zoom_links[data.item.id].status">
              <a class="zoom-link-ui" :href="zoom_links[data.item.id].zoomurl" target="blank">{{ zoom_links[data.item.id].zoomurl }}</a>
            </div>
            <div v-else>
            -
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>

        <template #cell(automate)="data">
          <div v-if="zoom_links[data.item.id]">
            <b-form-checkbox
              checked="false"
              name="check-button"
              v-model="zoom_links[data.item.id].status"
              switch
              inline
              @change="initZoom(data.item)"
            >
            </b-form-checkbox>
          </div>
          <div v-else>
            <b-form-checkbox
              v-if="automateAll"
              checked="true"
              name="check-button"
              switch
              inline
              disabled
            >
            </b-form-checkbox>
            <b-form-checkbox
              v-else
              checked="false"
              name="check-button"
              v-model="data.item.status"
              switch
              inline
              @change="initZoom(data.item)"
            >
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(avatar)="data">
          <div class="d-flex align-items-center">
            <b-avatar v-if="data.item.instructors[0]" :src="data.item.instructors[0].photo_urls.thumbnail_url" size="50px" />
            <div class="location-detail ml-2">
              <h5>{{ data.item.name }}</h5>
              <p class="mb-0" v-if="data.item.instructors[0]">{{ data.item.instructors[0].name }}</p>
            </div>
          </div>
        </template>

        <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>

      <div class="mt-2 mb-2">
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.count"
        per-page="20"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        align="center"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    </b-card-code>
    <b-card bg-variant="danger" text-variant="white" v-else>
        <b-card-text>Please connect Zoom and/or Mariana Tek to begin automating classes.</b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BTable, BAvatar, BBadge, BFormCheckbox, BPagination, BCardText, BCard
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BBadge,
    BAvatar,
    BFormCheckbox,
    BCardCode,
    BPagination,
  },
  data() {
    return {
      fields: [{ key: 'startdatetime', label: 'Time' }, { key: 'avatar', label: '' }, { key: 'location', label: 'Location' }, { key: 'date', label: 'Date' }, { key: 'zoomlink', label: 'URL' },'automate'],
      items: [],
      pagination: {
        page: 1,
      },
      zoom_links: {},
      currentpage: 1,
      automateAll: false,
    }
  },
  computed: {
    mtIntStatus() {
      if(this.$store.state.app.integrationstate && this.$store.state.app.integrationstate.mariana && this.$store.state.app.integrationstate.mariana.status == 'connected'){
        return true
      }
      else{
        return false
      }
    },
    zoomIntStatus() {
      if(this.$store.state.app.integrationstate && this.$store.state.app.integrationstate.zoom && this.$store.state.app.integrationstate.zoom.status == 'connected'){
        return true
      }
      else{
        return false
      }
    },
    user_data() {
      return this.$store.state.app.user_data
    },
  },
  filters: {
    timeformat: function(time) {
      // Check correct time format and split into components
      time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      if (time.length > 1) { // If time format correct
        time = time.slice (1);  // Remove full string match value
        time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join (''); // return adjusted time or original string
    },
    dateformat: function(dateiso) {
      const options = { year: "numeric", month: "long", day: "numeric" }
      return new Date(dateiso).toLocaleDateString(undefined, options)
    },
  },
  async mounted() {
    this.$store.commit('app/loadingState', true)
    var intState = this.$store.state.app.integrationstate
    if(Object.keys(intState).length === 0){
      this.$store.dispatch('app/getIntegrationStatus')
    }
    await this.$store.dispatch('app/getSubdomain')
    const automationData = await this.$http.post('https://live.ipstudio.co/api/get-usermeta', { meta_key: 'automate' })
    if(automationData.data.status != "failed"){
      const automationDataJSON = JSON.parse(automationData.data.meta_value);
      this.automateAll = automationDataJSON.status;
    }
    var subdomain = this.$store.state.app.subdomain;
    const self = this
    var date = new Date();
    var todaysdate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
        .toISOString()
        .split("T")[0];
    date.setDate(date.getDate() + 7);
    var enddate =  new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
      .toISOString()
      .split("T")[0];
    var classPost = '';
    if(this.$store.state.app.user_data.location != null){
      classPost = 'https://'+subdomain+'.marianatek.com/api/customer/v1/classes?min_start_date='+todaysdate+'&max_start_date='+enddate+'&is_live_stream=true&page_size=20&page='+this.pagination.page+'&location='+this.$store.state.app.user_data.location
    }
    else{
      classPost = 'https://'+subdomain+'.marianatek.com/api/customer/v1/classes?min_start_date='+todaysdate+'&max_start_date='+enddate+'&is_live_stream=true&page_size=20&page='+this.pagination.page
    }
    this.$http.get(classPost).then(res => {
      self.$store.commit('app/loadingState', false)
      self.items = res.data.results
      self.pagination = res.data.meta.pagination
    })
    this.$http.get('https://live.ipstudio.co/api/zoom-link').then(res => {
      self.$store.commit('app/loadingState', false)
      self.zoom_links = res.data
    })
  },
  updated() {
    var subdomain = this.$store.state.app.subdomain;
    const self = this
    if(this.currentpage != this.pagination.page){
      this.$store.commit('app/loadingState', true)
      var date = new Date();
      var todaysdate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
          .toISOString()
          .split("T")[0];
      date.setDate(date.getDate() + 7);
      var enddate =  new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
          .toISOString()
          .split("T")[0];
      var paginationURL = '';
      if(this.$store.state.app.user_data.location != null){
        paginationURL = 'https://'+subdomain+'.marianatek.com/api/customer/v1/classes?min_start_date='+todaysdate+'&max_start_date='+enddate+'&is_live_stream=true&page_size=20&page='+this.pagination.page+'&location='+this.$store.state.app.user_data.location
      }
      else{
        paginationURL = 'https://'+subdomain+'.marianatek.com/api/customer/v1/classes?min_start_date='+todaysdate+'&max_start_date='+enddate+'&is_live_stream=true&page_size=20&page='+this.pagination.page
      }
      this.$http.get(paginationURL).then(res => {
        self.$store.commit('app/loadingState', false)
        self.items = res.data.results
        self.pagination = res.data.meta.pagination
        self.currentpage = res.data.meta.pagination.page
      })
    }
  },
  methods: {
    initZoom(data) {
      var self = this;
      this.$store.commit('app/loadingState', true)
      if(this.zoom_links[data.id]){
        var status = this.zoom_links[data.id].status;
      }else if(data.status){
        var status = data.status;
      }else{
        var status = false;
      }
      var id = data.id;
      var date = data.start_date;
      const config = { id: id, status: status * 1, date: date, instructors: data.instructors }
      console.log(status)
      this.$http.post('https://live.ipstudio.co/api/zoom-link', config)
      .then(function (res) {
        var newzoomdata = {zoomurl: res.data.url, status: status}
        console.log(res)
        console.log(newzoomdata)
        self.$set(self.zoom_links, id, newzoomdata)
        console.log(self.zoom_links[id])
        self.$store.commit('app/loadingState', false)
        if(res.data.msg == "Daily Limit Reached."){
          self.$swal({
            title: 'Daily Limit Reached!',
            text: ' Upgrade to pro version in order to automate more classes.',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            confirmButtonText: 'Upgrade to Pro',
            buttonsStyling: false,
          }).then(function() {
              var emailBody = "Thank you for upgrading to Live by IPSTUDIO™ pro, please state your business name and desired start date and a member of our team will active this upgrade for you.";
              window.location = "mailto:hello@ipstudio.co?cc=admin@ipstudio.co&subject=Upgrade%20us%20to%20Live%20By%20IPSTUDIO™%20Pro&body="+emailBody;
          });
        }
        else{
          self.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.msg,
              icon: 'SaveIcon',
              variant: res.data.status,
            }
          })
        }
      })
      .catch(function (error) {
        console.log(error)
        self.$store.commit('app/loadingState', false)
        self.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong.',
            icon: 'AlertCircleIcon',
            variant: 'failed',
          }
        })
      })
    },
    triggerAutomateAll() {
      const config = { meta_key: 'automate', meta_value: JSON.stringify({"status": this.automateAll, "subdomain": this.$store.state.app.subdomain, "checked": []}) }
      this.$store.dispatch('app/saveUserMeta', config)
    },
  },
}
</script>

<style>
.automate-all-section {
    display: flex;
    justify-content: flex-end;
    margin-top: -45px;
    margin-bottom: 30px;
}
.automate-all-section .card-text {
    font-weight: bold;
    margin-right: 20px;
}
</style>
